import * as React from "react";
import { useEffect, useRef, useState } from "react";
import Typed from "typed.js";

import { Helmet } from "react-helmet";
import { Box, Stack } from "@mui/material";
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import Particles from "react-tsparticles";
import type { Engine } from "tsparticles-engine";
import { loadTrianglesPreset } from "tsparticles-preset-triangles";

import { useConfig } from "gatsby-theme-advanced";
import Layout from "../layouts";

import AcademicPage from "./subpages/AcademicPage";
import InvestorPage from "./subpages/InvestorPage";
import PersonalPage from "./subpages/PersonalPage";

import "./index.css"

const options = {
  background: {
      color: "#000000",
  },
  fullScreen: {
    enable: false
  },
  particles: {
      links: {
          distance: 120,
          enable: true,
          triangles: {
              enable: true,
              opacity: 0.1,
          },
      },
      move: {
          enable: true,
          speed: 2,
      },
      size: {
          value: 1,
      },
      shape: {
          type: "circle",
      },
  },
  preset: "triangles",
};

const AboutPage = (): JSX.Element => {
  const config = useConfig();

  // State variable for page setting
  const [setting, setSetting] = useState("personal");

  // Create Ref element for Typed JS.
  const el = useRef(null);
  useEffect(() => {
    if(el.current === null){
      return ()=>{};
    }

    const typed = new Typed(el.current, {
      strings: ["an entrepreneur.", "a researcher.", "a traveller.", "an activist.", "an ordinary guy.^1000"], // Strings to display
      // Speed settings, try diffrent values untill you get good results
      startDelay: 400,
      typeSpeed: 50,
      backSpeed: 50,
      backDelay: 400,
      loop: true,
    });

    // Destroying
    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <Layout>
      <div className="home-container">
        <Helmet title={`Home | ${config.website.title}`} />
        <div id="homeBanner">
          
        <Particles id="particles" options={options} init={async (engine: Engine): Promise<void> => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          await loadTrianglesPreset(engine);
        }} />



          <Stack direction="column" spacing={1} id="bannerText">
          <h1>Hi there!</h1>
          <h4>Welcome to my corner of the internet.</h4>
          <h4>I am <span ref={el}/></h4>
          </Stack>
        </div>

        <Box id="homeBody">
          <ToggleButtonGroup
            value={setting}
            exclusive
            onChange={
              (_event, newSetting: string) => {
                if (newSetting !== null) {
                  setSetting(newSetting);
                }
              }
            }
            aria-label="text alignment"
          >
            <ToggleButton value="personal" aria-label="personal">
              <AccountCircleIcon/> &nbsp; For People
            </ToggleButton>
            <ToggleButton value="investor" aria-label="investor">
              <BusinessRoundedIcon/> &nbsp; For Professionals
            </ToggleButton>
            <ToggleButton value="academic" aria-label="academic">
              <SchoolRoundedIcon/> &nbsp; For Professors
            </ToggleButton>

          </ToggleButtonGroup>
        {setting === "personal" && <PersonalPage/>}
        {setting === "investor" && <InvestorPage/>}
        {setting === "academic" && <AcademicPage/>}
        </Box>
      </div>
    </Layout>
  );
};

export default AboutPage;
