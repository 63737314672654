import * as React from "react";
import { Container, Stack } from "@mui/material";
import face from "../../icons/cartoon.png"
import "./AcademicPage.css"

// An html bib you get from downloading from scholar as bibtex and converting to html
const bib = (<div className="csl-bib-body">
<div data-csl-entry-id="datta2022deepshake" className="csl-entry">
  <div className="csl-left-margin">[1]</div><div className="csl-right-inline">Avoy Datta, <b> Daniel J Wu</b>, Weiqiang Zhu, Michael Cai, and William L Ellsworth. 2022. DeepShake: Shaking Intensity Prediction Using Deep Spatiotemporal RNNs for Earthquake Early Warning. <i>Seismological Society of America</i> 93, 3 (2022), 1636-1649.</div>
</div>
<div data-csl-entry-id="luo2021scalable" className="csl-entry">
  <div className="csl-left-margin">[2]</div><div className="csl-right-inline">Zelun Luo, <b> Daniel J Wu</b>, Ehsan Adeli, and Li Fei-Fei. 2021. Scalable differential privacy with sparse network finetuning. In <i>Proceedings of the IEEE/CVF Conference on Computer Vision and Pattern Recognition</i>, 5059-5068.</div>
</div>
<div data-csl-entry-id="wu2021oversupply" className="csl-entry">
  <div className="csl-left-margin">[3]</div><div className="csl-right-inline"><b> Daniel J Wu</b>. 2021. Oversupply of Limiting Cell Resources and the Evolution of Cancer Cells: A Review. <i>Frontiers in Ecology and Evolution</i> 9, (2021), 653622.</div>
</div>
<div data-csl-entry-id="wu2019energy" className="csl-entry">
  <div className="csl-left-margin">[4]</div><div className="csl-right-inline"><b> Daniel J Wu</b>, Athena Aktipis, and John W Pepper. 2019. Energy oversupply to tissues: a single mechanism possibly underlying multiple cancer risk factors. <i>Evolution, medicine, and public health</i> 2019, 1 (2019), 9-16.</div>
</div>
<div data-csl-entry-id="wu2021preliminary" className="csl-entry">
  <div className="csl-left-margin">[5]</div><div className="csl-right-inline"><b> Daniel J Wu</b>, Odgerel Badamjav, Vikrant V Reddy, Michael Eisenberg, and Barry Behr. 2021. A preliminary study of sperm identification in microdissection testicular sperm extraction samples with deep convolutional neural networks. <i>Asian journal of andrology</i> 23, 2 (2021), 135.</div>
</div>
<div data-csl-entry-id="wu2021continuous" className="csl-entry">
  <div className="csl-left-margin">[6]</div><div className="csl-right-inline"><b> Daniel J Wu</b> and Avoy Datta. 2021. Continuous weight balancing. <i>arXiv preprint arXiv:2103.16591</i> (2021).</div>
</div>
<div data-csl-entry-id="wu2021binarized" className="csl-entry">
  <div className="csl-left-margin">[7]</div><div className="csl-right-inline"><b> Daniel J Wu</b>, Avoy Datta, and Vinay Prabhu. 2021. Binarized Neural Networks for Resource-Constrained On-Device Gait Identification. <i>arXiv preprint arXiv:2103.16609</i> (2021).</div>
</div>
<div data-csl-entry-id="wu2020afro" className="csl-entry">
  <div className="csl-left-margin">[8]</div><div className="csl-right-inline"><b> Daniel J Wu</b>, Andrew C Yang, and Vinay U Prabhu. 2020. Afro-MNIST: Synthetic generation of MNIST-style datasets for low-resource languages. <i>arXiv preprint arXiv:2009.13509</i> (2020).</div>
</div>
<div data-csl-entry-id="wu2022deepfake" className="csl-entry">
  <div className="csl-left-margin">[9]</div><div className="csl-right-inline">Andrew C Yang, <b> Daniel J Wu</b>, and Ken Hong. 2022. Practical Deepfake Detection: Vulnerabilities in Global Contexts. <i>arXiv preprint arXiv:2206.09842</i> (2022).</div>
</div>
</div>)

function AcademicPage(){


    return(
        <Container id="academicContainer">
            <Stack direction="column" spacing={2}>
            <Stack direction="row" spacing={2}>
                <img src={face} alt="My face."/>
                <Stack direction="column" spacing={1}>
                    <h2 className="times">Daniel Wu</h2>
                    <p className="monospace"> Former AI Researcher </p>
                    <p className="monospace"><b>Email:</b> danjwu (at) cs (dot) stanford (dot) edu</p>
                    <Stack direction="row" spacing={4}>
                    <p className="monospace"><a href="https://scholar.google.com/citations?user=xG9IFH8AAAAJ&hl=en">Google Scholar</a></p>
                    <p className="monospace"><a href="https://orcid.org/0000-0002-8537-6044"> ORCiD</a></p>
                    </Stack>
                </Stack>
            </Stack>
            
            <h2 className="times">Education</h2>
            <p className="monospace"><b>2018-2020, Spring 2022</b> Stanford University Bachelors in Computer Science</p>

            <h2 className="times">Teaching</h2>
            <Stack direction="row" spacing={2} justifyContent="space-between">
            <p className="monospace"><a href="https://web.stanford.edu/class/cs106s/">CS 106S</a> CS For Social Good</p> <p className="monospace">Winter 2019</p>
            </Stack>
            <Stack direction="row" spacing={2} justifyContent="space-between">
            <p className="monospace"><a href="https://web.stanford.edu/class/cs21si/">CS 21SI</a> AI For Social Good</p> <p className="monospace">Spring 2019</p>
            </Stack>
            <Stack direction="row" spacing={2} justifyContent="space-between">
            <p className="monospace"><a href="https://web.stanford.edu/class/med18si/">MED 18SI</a> Artificial Intelligence in Medicine and Healthcare Ventures</p> <p className="monospace">Winter 2021</p>
            </Stack>

            <h2 className="times">Publications</h2>
            {bib}

            </Stack>

        </Container>
    );
}

export default AcademicPage;